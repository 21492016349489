import { ReactNode } from 'react'
import 'assets/styles/robotoFm.scss'
import { Col, Layout, Row, Space } from 'antd'
import { ThemeProvider } from 'containers/app/components/providers/ThemeProvider'
import { Footer } from 'containers/app/screens/SignIn/component/page/SignInFooter'
import { signInLayoutTheme } from '../data/theme/signInLayoutTheme'
import styles from './styles.module.scss'
import { signInFooter } from '../data/theme/footerSignInTheme'

const { Content } = Layout

type ISignInLayoutProps = {
  children: ReactNode
}

const SignInLayout = ({ children }: ISignInLayoutProps) => (
  <ThemeProvider theme={signInLayoutTheme}>
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <Row justify='center' style={{ flex: '0 1 360px' }}>
          <Col style={{ width: '100%' }}>
            <Space.Compact
              direction='vertical'
              block
              style={{
                height: '100%',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              {children}
              <Footer theme={signInFooter} />
            </Space.Compact>
          </Col>
        </Row>
      </Content>
    </Layout>
  </ThemeProvider>
)

export default SignInLayout
